import React, { useEffect } from 'react';
import { graphql, Link } from 'gatsby';

export default function Blog({ data }: { data: any }) {
  const { posts } = data.blog;

  //   const url: string = posts.frontmatter.slug;

  useEffect(() => {
    console.log(data.blog);
  });

  return (
    <div>
      <h1>My blog posts</h1>

      {posts.map((post: any) => (
        <article key={post.id}>
          <h2>{post.frontmatter.title}</h2>
          <small>
            {post.frontmatter.author}, {post.frontmatter.date}
          </small>
          <Link to={post.frontmatter.slug}>
            <p>{post.excerpt}</p>
          </Link>
        </article>
      ))}
    </div>
  );
}

export const pageQuery = graphql`
  query MyQuery {
    blog: allMarkdownRemark {
      posts: nodes {
        frontmatter {
          date(fromNow: true)
          title
          author
          slug
        }
        excerpt
        id
      }
    }
  }
`;
